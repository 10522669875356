<!--
 * @Author: filename
 * @Description: 新增/编辑文书信息
-->
<template>
    <div>
        <el-dialog
            :visible.sync="isDialog"
            v-bind="$attrs"
            :title="data._type === 'add' ? '新增文书信息' : data._type === 'check' ? '查看文书信息' : '编辑文书信息'"
            :before-close="() => $emit('update:visible', false)"
            width="800px"
            top="15px"
        >
            <el-form
                ref="formRef"
                :model="form"
                label-width="auto"
            >
                <el-form-item label="方案定位：" prop="type_label_id">
                    <el-select
                        v-model="form.order_plan_id"
                        placeholder="请选择"
                        style="width: 500px"
                        :loading="isSelectLoading"
                        :disabled="!!data.clerical_id"
                    >
                        <el-option
                            v-for="item in professionData"
                            :key="item.id"
                            :label="item.school_major"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <el-tabs v-model="activeName" v-loading="isLoading">
                <el-tab-pane
                    v-for="(item, i) in tabs"
                    :key="i"
                    :label="item.label"
                    :name="item.name"
                >
                    <component
                        :is="item.component"
                        v-if="activeName === item.name"
                        :ref="item.refName"
                        :data="data"
                    />
                </el-tab-pane>
            </el-tabs>
            <span v-if="data._type !== 'check'" slot="footer" class="dialog-footer">
                <el-button @click="$emit('update:visible', false)">取 消</el-button>
                <el-button
                    type="primary"
                    :loading="isSaveLoading"
                    @click="handleSave"
                >
                    保存
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    getPlanList,
    addLxClericalRecommend,
    addLxClericalPs,
    editLxClericalRecommend,
    editLxClericalPs,
} from "@/api/writ.js"
import RecommendedInfo from "./RecommendedInfo.vue"
import PsInfo from "./PsInfo.vue"
export default {
    components: {
        RecommendedInfo,
        PsInfo
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    data() {
      return {
            form: {
                order_plan_id: this.data.order_plan_id,
            },
            professionData: [],
            activeName: '1',
            isSelectLoading: false,
            isSaveLoading: false,
            tabs: [
                { label: '推荐信信息', name: '1', component: 'RecommendedInfo', refName: 'recommendedRef' },
                { label: 'PS信息', name: '2', component: 'PsInfo', refName: 'psRef' },
            ],
            details: null,
            isLoading: false,
      }
    },
    computed: {
        isDialog: {
            get() {
                return this.$attrs.visible;
            },
            set: val => this.$emit('update:visible', val)
        }
    },
    created() {
        this.getPlan()
        if (this.data.clerical_type) {
            this.activeName = String(this.data.clerical_type)
            this.tabs = this.tabs.filter(item => item.name === this.activeName)
        }
    },
    methods: {
        async getPlan() {
            try {
                this.isSelectLoading = true
                const { data } = await getPlanList({ order_id: this.$store.state.user.orderId })
                this.professionData = data
            } catch (error) {
                console.log(error)
            } finally {
                this.isSelectLoading = false
            }
        },
        // 保存
        async handleSave() {
            const valid = this.activeName === '1' ? await this.$refs.recommendedRef[0].checkValid() : true
            if (valid) {
                try {
                    this.isSaveLoading = true
                    const parm = {
                        ...this.$refs[this.activeName === '1' ? 'recommendedRef' : 'psRef'][0].form,
                        ...this.form
                    }
                    const { code } = this.data.clerical_id
                                        ? await (this.activeName === '1' ? editLxClericalRecommend(parm) : editLxClericalPs(parm))
                                        : await (this.activeName === '1' ? addLxClericalRecommend(parm) : addLxClericalPs(parm))
                    if (code === 200) {
                        this.$message.success('保存成功！')
                        this.$emit('handleSuccess', ' ')
                        this.$emit('update:visible', false)
                    }
                } finally {
                    this.isSaveLoading = false
                }

            }
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
    overflow: auto;
    max-height: 700px;
}
</style>
