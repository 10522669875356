<!--
 * @Author: filename
 * @Description: 新增/编辑文书信息 - 推荐信信息
-->
<template>
    <el-form
        ref="formRef"
        v-loading="isLoading"
        :model="form"
        label-width="160px"
        :rules="rules"
    >
        <el-form-item label="推荐信类型：" prop="type">
            <el-radio-group
                v-model="form.type"
                aria-label="label position"
                :disabled="data._type !== 'add'"
                @change="handleRadio"
            >
                <el-radio :label="1">学术推荐信</el-radio>
                <el-radio :label="2">工作推荐信</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="推荐人姓名：" prop="referee_name">
            <el-input v-model="form.referee_name" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人所在单位：" prop="referee_work_unit">
            <el-input v-model="form.referee_work_unit" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人职务：" prop="referee_position">
            <el-input v-model="form.referee_position" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人性别：">
            <el-input v-model="form.referee_sex" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人职称：">
            <el-input v-model="form.referee_professional_title" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人所在单位地址：">
            <el-input v-model="form.referee_professional_address" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人所在单位邮编：">
            <el-input v-model="form.referee_unit_postcode" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人E-mail：">
            <el-input v-model="form.referee_email" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="推荐人电话：">
            <el-input v-model="form.referee_phone" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item
            v-if="form.type === 1"
            class="form-item"
            prop="referee_relation_teacher"
            label="1.推荐人与您是何时、何地以及通过何种途径认识？联系是否还密切？如果是教过你某门课程的老师，你的那门课程考试结果如何（如分数，排名等等），课堂表现如何？"
        >
            <el-input
                v-model="form.referee_relation_teacher"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            v-if="form.type === 2"
            class="form-item"
            prop="referee_relation_leader"
            label="1.推荐人与您是何时、何地以及通过何种途径认识？联系是否还密切？如果是你的领导，请简述有过的工作交集。"
        >
            <el-input
                v-model="form.referee_relation_leader"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="2.推荐人是否指导您进行过什么研究/实践？最总取得了什么成绩？什么时间参与的？担任什么角色？是否遇到过挫折 / 问题？最终是如何解决问题的？重点突出您的理解能力、分析能力、领导能力、解决问题的能力、团队精神以及活动研究成果。"
        >
            <el-input
                v-model="form.referee_ability"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="3.您给这位推荐人留下什么印象？请通过具体事例说明您如何给推荐人留下了深刻的印象，切忌空泛。"
        >
            <el-input
                v-model="form.referee_impression"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="4.推荐人认为您在哪方面需要提升？"
        >
            <el-input
                v-model="form.referee_ability_improve"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            v-if="form.type === 1"
            class="form-item"
            label="5.推荐人是否了解您其他方面的能力，比如你的各科综合成绩、其他个人魅力、得过何种荣誉等。 请综合评价并尽量用事例说明。"
        >
            <el-input
                v-model="form.referee_achievement"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            v-if="form.type === 2"
            class="form-item"
            label="5.推荐人是否了解您其他方面的能力，比如您的管理潜力、您与其他部门的沟通流畅度、您的其他社会价值、得过何种荣誉等。 请综合评价并尽量用事例说明。"
        >
            <el-input
                v-model="form.referee_potentiality"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item label="上传附件：">
            <UploadFile
                v-if="!isDisabled"
                :file-list="files"
                :button-text="'上传附件'"
                :dis-type="''"
                :limit="500"
                :file-type="[]"
                @success="handleSuccess"
            />
            <div v-if="form.attachment.length > 0" class="file-list">
                <div v-for="(item, index) in form.attachment" :key="index">
                    <el-link type="primary" :href="$utils.toOss(item.fileUrl)" target="_blank">{{ item.fileName }}</el-link>
                    <span v-if="!isDisabled" class="del-btn">
                        <i class="el-icon-error" @click="deleteFile(index)" />
                    </span>

                </div>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import {
    getLxClericalRecommend,
} from "@/api/writ.js"
export default {
    props: {
        data: {
            type: Object,
            default: () => null
        },
    },
    data() {
      return {
        form: {
            order_id: this.$store.state.user.orderId, // 订单ID
            order_plan_id: '', // 方案ID
            type: 1, // 类型：1 学术推荐信 2 工作推荐信
            referee_name: '', // 推荐人姓名
            referee_work_unit: '',
            referee_position: '',
            referee_sex: '',
            referee_professional_title: '',
            referee_professional_address: '',
            referee_unit_postcode: '',
            referee_email: '',
            referee_phone: '',
            referee_relation_teacher: '',
            referee_relation_leader: '',
            referee_ability: '',
            referee_impression: '',
            referee_ability_improve: '',
            referee_achievement: '',
            referee_potentiality: '',
            attachment: [],
        },
        rules: {
            type: [{ required: true, message: "请选择", trigger: "change" }],
            referee_name: [{ required: true, message: "请填写", trigger: "blur" }],
            referee_work_unit: [{ required: true, message: "请填写", trigger: "blur" }],
            referee_position: [{ required: true, message: "请填写", trigger: "blur" }],
            referee_relation_teacher: [{ required: true, message: "请填写", trigger: "blur" }],
            referee_relation_leader: [{ required: true, message: "请填写", trigger: "blur" }],
        },
        isLoading: false,
        isDisabled: false,
        files: [],
      }
    },
    created() {
        // 查看
        if (this.data._type === 'check') {
            this.isDisabled = true
        }
        if (this.data.clerical_id) {
            this.getDetails()
        }
    },
    methods: {
        // 校验字段
        checkValid() {
            return this.$refs.formRef.validate()
        },
        // 获取详情
        async getDetails() {
            try {
                this.isLoading = true
                const parm = {
                    id: this.data.clerical_id
                }
                const { code, data } = await getLxClericalRecommend(parm)
                if (code === 200) {
                    this.form = data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 上传
        handleSuccess(file) {
            this.form.attachment.push({
                fileName: file.fileInfo.name,
                fileUrl: file.fileInfo.url
            })
        },
        // 删除
        deleteFile(index) {
            this.form.attachment.splice(index, 1)
        },
        // 切换清空内容
        handleRadio() {
            for(let key in this.form) {
                if (key !== 'order_id' && key !== 'type' & key !== 'attachment') {
                    this.form[key] = ''
                }
            }
            this.form.attachment = []
        }
    }
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__label {
        width: 100%!important;
        text-align: left;
        line-height: 23px;
    }
}
.del-btn {
    cursor: pointer;
    margin-left: 5px;
}
</style>
