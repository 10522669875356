<!--
 * @Author: filename
 * @Description: 新增/编辑文书信息 - PS信息
-->
<template>
    <el-form
        ref="formRef"
        v-loading="isLoading"
        :model="form"
        label-width="160px"
    >
        <el-form-item
            class="form-item"
            label="1.您选择申请该课程的原因是什么？例如：兴趣起源、家庭影响、学习成长经历，日常生活小事例，读书感悟启发等有个性化独特性的切入点。"
        >
            <el-input
                v-model="form.reason"
                :disabled="isDisabled"
                type="textarea"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="2.阐述您对即将申请专业的理解。并结合您之前的学习经历分析您申请此专业的优势，结合具体事例进行说明。重点突出您的理解能力、分析能力、总结能力、解决问题的能力以及活动研究成果。如果是转专业（本科主修专业和申请的研究生专业不在同一范畴，比如本科学英语专业，研究生申请市场营销），请说明转专业的原因以及您打算如何克服转专业学习的困难。"
        >
            <el-input
                v-model="form.understanding_of_profession"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="3.是否获得过奖学金？列出奖学金的名字（如三好学生奖学金，优秀学生奖学金等）和颁发机构，以及获奖时间；是否发表过论文？若有，请提供这些信息：论文题目、主要内容、刊物名称和级别、论文摘要，发表时间。"
        >
            <el-input
                v-model="form.scholarship"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="4.参与过哪些校内校外的组织或活动（比如：社团，学生会，班干部等）？（列举和申请课程方向有关联的）什么时间参与的？担任什么角色？谈谈让您印象深刻的事及感受和收获？是否遇到过挫折 / 问题？最终是如何解决问题的？"
        >
            <el-input
                v-model="form.activity"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="5.您的职业规划是怎样的？学习这个课程对您未来职业发展有何帮助？具体描述毕业后将来的打算，比如毕业之后想去什么样的单位做什么样的工作。三到五年积累经验之后自己又有什么别的打算等等。（越具体越好）"
        >
            <el-input
                v-model="form.career_planning"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="6.为何申请该学校该专业？可以在学校官网上了解学校的排名及课程设置等，学校哪方面符合您的求学要求，尽量表达自己的认识。也可以结合专业设置和您的职业规划简单写写咱们待提升方向和学校课程的匹配度。"
        >
            <el-input
                v-model="form.reason_of_profession"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="7.如果您被学校录取了，您可以给学校带来什么贡献？"
        >
            <el-input
                v-model="form.contribution"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item
            class="form-item"
            label="8.有无其它对申请有帮助的信息，如交换经历，读书心得等个性化信息。"
        >
            <el-input
                v-model="form.other_information"
                type="textarea"
                :disabled="isDisabled"
                :rows="2"
            />
        </el-form-item>
        <el-form-item label="上传附件：">
            <UploadFile
                v-if="!isDisabled"
                :file-list="files"
                :button-text="'上传附件'"
                :dis-type="''"
                :limit="500"
                :file-type="[]"
                @success="handleSuccess"
            />
            <div v-if="form.attachment.length > 0" class="file-list">
                <div v-for="(item, index) in form.attachment" :key="index">
                    <el-link type="primary" :href="$utils.toOss(item.fileUrl)" target="_blank">{{ item.fileName }}</el-link>
                    <span v-if="!isDisabled" class="del-btn">
                        <i class="el-icon-error" @click="deleteFile(index)" />
                    </span>

                </div>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import {
    getLxClericalPs,
} from "@/api/writ.js"
export default {
    props: {
        data: {
            type: Object,
            default: () => null
        },
    },
    data() {
      return {
        form: {
            order_id: this.$store.state.user.orderId, // 订单ID
            order_plan_id: '', // 方案ID
            reason: '',
            understanding_of_profession: '',
            scholarship: '',
            activity: '',
            career_planning: '',
            reason_of_profession: '',
            contribution: '',
            other_information: '',
            attachment: [],
        },
        isLoading: false,
        isDisabled: false,
        files: []
      }
    },
    created() {
        // 查看
        if (this.data._type === 'check') {
            this.isDisabled = true
        }
        if (this.data.clerical_id) {
            this.getDetails()
        }
    },
    methods: {
        // 获取详情
        async getDetails() {
            try {
                this.isLoading = true
                const parm = {
                    id: this.data.clerical_id
                }
                const { code, data } = await getLxClericalPs(parm)
                if (code === 200) {
                    this.form = data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 上传
        handleSuccess(file) {
            this.form.attachment.push({
                fileName: file.fileInfo.name,
                fileUrl: file.fileInfo.url
            })
        },
        // 删除
        deleteFile(index) {
            this.form.attachment.splice(index, 1)
        }
    }
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__label {
        width: 100%!important;
        text-align: left;
        line-height: 23px;
    }
}
.del-btn {
    cursor: pointer;
    margin-left: 5px;
}
</style>
