<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">{{ this.$store.state.user.userName }},您好</div>
                <div class="current-progress">当前办理项目: {{ productName }}</div>
                <div class="sign-time">签约时间: {{ signTime }}</div>
            </div>
        </div>
        <div class="text-box">
            <div style="margin-bottom: 20px;">
                方案定位：
                <el-select
                    v-model="form.order_plan_id"
                    placeholder="请选择"
                    style="width: 500px"
                    :loading="isSelectLoading"
                >
                    <el-option
                        v-for="item in professionData"
                        :key="item.id"
                        :label="item.school_major"
                        :value="item.id"
                    />
                </el-select>
                <el-button
                    style="margin-left: 20px;"
                    type="primary"
                    :disabled="!form.order_plan_id"
                    @click="handleEdit(null, 'add')"
                >
                    新增
                </el-button>
            </div>
            <!-- 列表 -->
            <el-table
                v-loading="isLoading"
                :data="tableData"
                :header-cell-style="{
                    backgroundColor: '#F5F7FA',
                    color: '#606266',
                    fontWeight: 'bold'
                }" resizable border
            >
                <el-table-column prop="school_name" label="学校" />
                <el-table-column prop="school_major" label="专业（中文）" />
                <el-table-column prop="school_major_en" label="专业（英文）" />
                <el-table-column prop="entrance_time" label="年份" />
                <el-table-column prop="quarter" label="季度" />
                <el-table-column prop="information_type" label="信息类型">
                    <template slot-scope="{row}">
                        {{ row.information_type === 1 ? 'PS信息' : row.information_type === 2 ? '学术推荐信' : '工作推荐信' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{row}">
                        <el-button type="text" size="small" @click="handleEdit(row, 'edit')">编辑</el-button>
                        <el-button type="text" size="small" @click="handleEdit(row, 'check')">查看</el-button>
                        <el-popconfirm
                            placement="top"
                            title="确认删除？"
                            @onConfirm="handleDelRow(row)"
                        >
                            <el-button
                                slot="reference"
                                style="margin-left: 10px;"
                                type="text"
                                size="small"
                            >删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增、编辑文书信息 -->
        <writ-info-dialog
            v-if="isWritInfoDialog"
            :visible.sync="isWritInfoDialog"
            :data="{ ...form, ...rowItem }"
            @handleSuccess="getList"
        />
    </div>
</template>

<script lang="javascript">
import { getGangedProgress } from "@/api/workfow.js"
import { getPlanList, delLxClerical, getLxClericalRecommend, getClericalInfoList } from "@/api/writ.js"
import WritInfoDialog from "./C/WritInfoDialog.vue"
export default {
    name: "WritInfo",
    components: {
        WritInfoDialog,
    },
    data() {
        return {
            signTime: '',
            productName: '',
            schemePosition: "", //方案定位
            form: {
                order_plan_id: ''
            },
            tableData: [],
            isWritInfoDialog: false,
            isLoading: false,
            professionData: [],
            isSelectLoading: false,
            rowItem: {},
        }
    },
    created() {
        this.$store.commit('user')
        this.$store.commit('setType')
        this.$store.commit('setName')
        if(['留学'].includes(this.$store.state.type)) {
            this.fetchGangedProgress()
        }
        this.getPlan()
        this.getList()
    },
    methods: {
        // 项目进度-服务端联动
        async fetchGangedProgress() {
            let { code, data } = await getGangedProgress({
                orderId: this.$store.state.user.orderId,
                planId: this.schemePosition,
                productType: 3
            });
            if(code === 200) {
                this.signTime = data.signTime
                this.productName = data.productName
            }
        },
        async getList() {
            try {
                this.isLoading = true
                const { code, data } = await getClericalInfoList({ order_id: this.$store.state.user.orderId })
                if (code === 200) {
                    this.tableData = data || []
                }
            } finally {
                this.isLoading = false
            }
        },
        // 获取专业
        async getPlan() {
            try {
                this.isSelectLoading = true
                const { data } = await getPlanList({ order_id: this.$store.state.user.orderId })
                this.professionData = data
            } catch (error) {
                console.log(error)
            } finally {
                this.isSelectLoading = false
            }
        },

        // 删除
        async handleDelRow(row) {
            try {
                const { code } = await delLxClerical({
                    id: row.clerical_id,
                    type: row.clerical_type
                })
                if (code === 200) {
                    this.$message.success('删除成功！')
                    this.getList()
                }
            }
            catch(err) {
                console.log(err)
            }
        },
        // 编辑
        async handleEdit(row = null, type) {
            if (row) {
                row._type = type
                row.order_plan_id = row.id
                this.rowItem = row
            } else {
                this.rowItem = {
                    _type: type
                }
            }
            this.isWritInfoDialog = true
        }
    }
}
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.top-title {
    width: 1000px;
    height: 130px;
    margin: 0 auto;
}

.name {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;
    padding-top: 28px;
    margin-bottom: 12px;
}

.current-progress {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 6px;
}
.tips_txt {
    margin: 10px 20px 0;
    color: #c0c4cc;
}

.sign-time {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
}
.text-box {
    width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
}
</style>
